import React, { createContext, useContext, useEffect, useState } from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom'
import {message} from 'antd'
import {postLogin} from '@/api/auth'
import {ADMIN} from '@/utils/constants'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [role, setRole] = useState('')
  const [propertyRole, setPropertyRole] = useState({})
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate() // Use useNavigate for navigation
	const [searchParams] = useSearchParams()

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Logic for decoding the token and setting the user
      setIsAuthenticated(false);
    }
    const role = localStorage.getItem('role')
    if (role) {
      setRole(role)
    }
    const propertyRole = localStorage.getItem('propertyRole')
    if (propertyRole) {
      setPropertyRole(JSON.parse(propertyRole))
    }
  }, []);
  
	const handleSubmit = async (values) => {
    setLoading(true)
		const response = await postLogin(values).catch(err => {
			console.error('Login failed', err.message)
      setLoading(false)
		})
    setLoading(false)

		if (response) {
      message.success('Login Successfully!')
			// Store the token in localStorage or sessionStorage
			localStorage.setItem('token', response.token)
      setIsAuthenticated(true);
			// admin manager server
      const role = response.isAdminOrSuperuser ? ADMIN : ''
			localStorage.setItem('role', role)
      setRole(role)
			localStorage.setItem('propertyRole', JSON.stringify(response.propertyRole || {}))
      setPropertyRole(response.propertyRole || {})
      localStorage.setItem('company', response.companyId ||  '1')
			const redirectUrl = searchParams.get('redirect')
			// Navigate to the company page
			navigate(redirectUrl || '/company/' + (response.companyId ||  '1'))
		}
	}

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, propertyRole, loading, handleSubmit, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export the custom hook
export const useAuth = () => useContext(AuthContext);
