import React, {useEffect, useState, useMemo, useContext} from 'react'
import {PropertiesContext} from '@/contexts/PropertiesContext'
// import {CSVLink} from 'react-csv'
import {useParams} from 'react-router-dom'
import usePaginatedData from '../../hooks/usePaginatedData'
import CustomForm from '@/components/CustomeForm'
import {SearchOutlined, DownloadOutlined, PlusOutlined, EditOutlined} from '@ant-design/icons'
import {Button, Table, Space, Popconfirm, ConfigProvider, Empty, message} from 'antd'
import ShiftModal from './SubmitForm/ShiftModal'
import SubmitForm from './SubmitForm/SubmitForm'
import {TABLE_COL_HANDLE_TYPE, SHIFT_TYPE_DICT, HOUSE_AREA_DICT} from '@/utils/dict'
import dayjs from 'dayjs'
import service from '@/config/axios/index.js'
import {postCalculateTips} from '@/api/company'
import {getRollAll} from '@/api/property'
import HideColumnModal from './HideColumnModal/HideColumnModal'
import BatchEditEmployee from './SubmitForm/BatchEditEmployee'
import './GenericTable.css'

const SORT_MAP = {
	ascend: 'asc',
	descend: 'desc'
}

const GenericTable = ({
	endpoint,
	filtersConfig,
	errorMessage,
	noDataMessage,
	tableName,
	excludeColumns = [],
	selectionType = 'checkbox', // defautl checkbox || radio
	formType,
	columnConfig,
	rowClassName,
	defaultParams = {},
	options = {}, // extend options control button and action show or hide
	summaryFunc,
	downloadActions //  download handle
}) => {
	const [formData, setFormData] = useState({})
	const [error, setError] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [showBatchModal, setShowBatchModal] = useState(false)
	const {properties, fetchProperties} = useContext(PropertiesContext) // Fetch properties from context
	const [shiftTypeList, setShiftTypeList] = useState([])
	const [houseAreaList, setHouseAreaList] = useState([])
	const [positionList, setPositionList] = useState([])
	const [roleList, setRoleList] = useState([])
	const [sortedInfo, setSortedInfo] = useState({});
	const {companyId, propertyId} = useParams()
	const {data = [], total, page, pageSize, fetchData, loading, error: fetchError, summary} = usePaginatedData(endpoint + '/all')
	// Default Params
	const [searchParams, setSearchParams] = useState({})
	const [checkedList, setCheckedList] = useState([])
	const [selectedRows, setSelectedRows] = useState([])

	useEffect(() => {
		let checkList = tableConfig.map(k => {
			if (k instanceof Object) {
				return k.key
			} else {
				return k
			}
		})
		if (extendOptions.showAction) {
			checkList.push('action')
		}
		setCheckedList(checkList)
	}, [])

	const extendOptions = useMemo(() => {
		return {
			defaultFetch: true,
			showAction: true,
			deleteBtn: true,
			editBtn: true,
			calcBtn: false,
			showTopAction: true,
			topAddBtn: true,
			topDownloadBtn: true,
			topHideColumnBtn: true,
			topBatchEdit: false,
			...options,
		}
	}, [options])

	const formConfig = useMemo(() => {
		const newConfig = Object.assign({}, filtersConfig)
		newConfig.data &&
			newConfig.data.push({
				key: 'operateBtns',
				$input: [
					{
						$input: {
							type: 'button',
							buttonType: 'primary',
							icon: <SearchOutlined />,
							children: 'Search',
							htmlType: 'submit',
						},
					},
				],
			})
		return newConfig
	}, [filtersConfig])

	const tableConfig = useMemo(() => {
		let columns = []
		if (columnConfig) {
			columns = columnConfig.map(k => {
				if (k instanceof Object) {
					if (k.sorter) {
						k.sortOrder = sortedInfo.columnKey === k.dataIndex ? sortedInfo.order : null
					}
					// Extra Handle
					if (k.type === TABLE_COL_HANDLE_TYPE.DEFAULT) {
						return {
							...k,
							type: null,
							render: (_, record) => {
								return _ !== null && _ !== undefined && String(_)
							}
						}
					} else if (k.type === TABLE_COL_HANDLE_TYPE.ARRAY_TO_STRING) {
						return {
							...k,
							type: null,
							render: (_, record) => {
								return _ && _.join(';')
							},
						}
					} else if (k.type === TABLE_COL_HANDLE_TYPE.OBJECT_TO_STRING) {
						return {
							...k,
							type: null,
							render: (_, record) => {
								return _ && JSON.stringify(_)
							},
						}
					} else if (k.type === TABLE_COL_HANDLE_TYPE.DATE_TO_STRING) {
						return {
							...k,
							type: null,
							render: (_, record) => {
								return _ && dayjs(_).format('YYYY-MM-DD HH:mm:ss')
							},
						}
					} else if (k.type === TABLE_COL_HANDLE_TYPE.DATE_TO_STRING_DATE) {
						return {
							...k,
							type: null,
							render: (_, record) => {
								return _ && dayjs(_).format('YYYY-MM-DD')
							},
						}
					} else {
						return k
					}
				} else {
					let title = k.replace(/([A-Z])/g, function (s) {
						return ' ' + s
					})
					title = title[0].toUpperCase() + title.substr(1)
					return {
						title,
						dataIndex: k,
						key: k,
					}
				}
			})
		} else if (data.length > 0) {
			const cols = Object.keys(data[0])
			columns = cols
				.filter(col => !excludeColumns.includes(col))
				.map(k => {
					let title = k.replace(/([A-Z])/g, function (s) {
						return ' ' + s
					})
					title = title[0].toUpperCase() + title.substr(1)
					return {
						title,
						dataIndex: k,
						key: k,
					}
				})
		}
		if (extendOptions.showAction) {
			console.log(extendOptions)
			columns.push({
				title: 'Action',
				key: 'action',
				render: (_, record) => (
					<Space size="middle">
						{extendOptions.editBtn ? (
							<a
								className="edit-button"
								onClick={() => {
									createOrEditForm(record)
								}}
							>
								Edit
							</a>
						) : null}
						{extendOptions.calcBtn ? (
							<a
								className="edit-button"
								onClick={() => {
									calclateTips(record)
								}}
							>
								CalclateTips
							</a>
						) : null}
						{extendOptions.deleteBtn ? (
							<Popconfirm
								title="Delete the item"
								description="Are you sure you want to delete this item?"
								onConfirm={() => {handleDelete(record)}}
								okText="Yes"
								cancelText="No"
							>
								<a className="delete-button">Delete</a>
							</Popconfirm>
						) : null}
					</Space>
				),
			})
		}
		if (extendOptions.topHideColumnBtn) {
			return columns.map(item => {
				item.hidden = !checkedList.includes(item.key)
				return item
			})
		} else {
			return columns
		}
	}, [data, columnConfig, extendOptions, checkedList])

	const dateColumn = useMemo(() => {
		let coloums = columnConfig
			? columnConfig
					.filter(k => {
						return k && k.type && k.type === TABLE_COL_HANDLE_TYPE.DATE_TO_STRING
					})
					.map(k => k.dataIndex)
			: []
		return coloums.concat(['startDate', 'endDate'])
	}, [columnConfig])

	// select config
	const rowSelection = useMemo(() => {
		return {
			type: selectionType,
			onChange: (selectedRowKeys, selectedRows) => {
				console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
				setSelectedRows(selectedRows)
			},
		}
	}, [selectionType])

	// fetch data after mounted
	useEffect(() => {
		const fetch = async () => {
			try {
				const params = {
					page: page - 1,
					pageSize,
					...defaultParams,
				}
				setSearchParams(params)
				await fetchData(params)
			} catch (err) {
				setError(errorMessage || 'Failed to fetch data.')
			}
		}
		extendOptions.defaultFetch && fetch()
	}, [])

	// Form Search
	const onSearch = async val => {
		const params = {
			page: 0,
			pageSize,
		}
		Object.keys(val).forEach(k => {
			if (val[k] !== undefined && val[k] !== '' && val[k] !== null) {
				if (k.startsWith('_')) {
					val[k][0] !== undefined && (params[k.replace('_', '') + 'Min'] = val[k][0])
					val[k][1] !== undefined && (params[k.replace('_', '') + 'Max'] = val[k][1])
				} else if (val[k]?.$isDayjsObject) {
					params[k] = val[k].format('YYYY-MM-DD')
				} else if (val[k][0]?.$isDayjsObject) {
					params['min' + k] = val[k][0].format('YYYY-MM-DD') + 'T00:00:00'
					params['max' + k] = val[k][1].format('YYYY-MM-DD') + 'T23:59:59'
				} else {
					params[k] = val[k]
				}
			}
		})
		setSearchParams(params)
		await fetchData(params)
	}

	// Size Search
	const onTableChange = async (pagination,filters,sorter) => {
		console.log(pagination, sorter)
		setSortedInfo(sorter)
		let params = {
			...searchParams,
			page: pagination.current - 1,
			pageSize: pagination.pageSize,
		}
		if (params.sort) {
			delete params.sort
		}
		if (sorter.order) {
			params.sort = `${sorter.column.sortKey || sorter.columnKey},${SORT_MAP[sorter.order]}`
		}
		setSearchParams(params)
		await fetchData(params)
	}

	const createOrEditForm = async (rowInfo = {}) => {
		if (rowInfo.propertyId) {
			let property = properties.find(v => v.id == rowInfo.propertyId)
			if (property && property.shiftTypeAvailable) {
				const shiftTypeList = SHIFT_TYPE_DICT.filter(v => property.shiftTypeAvailable.includes(v.value))
				setShiftTypeList(shiftTypeList)
			} else {
				setShiftTypeList([])
			}
			if (property.houseAreaAvailable) {
				let keys = Object.keys(property.houseAreaAvailable)
				const houseAreaList = HOUSE_AREA_DICT.filter(v => keys.includes(v.value))
				setHouseAreaList(houseAreaList)
				let selectHouseArea = houseAreaList.find(v => v.value === rowInfo.houseArea)
				if (selectHouseArea) {
					setPositionList(selectHouseArea.positionList)
				} else {
					setPositionList([])
				}
			} else {
				setHouseAreaList([])
				setPositionList([])
			}
			// set roleList
			if (formType === "EmployeeInfo") {
				const res = await getRollAll(rowInfo.propertyId).catch(err => {
					setRoleList([])
				})
				if (res && res.content) {
					setRoleList(res.content.map(v => ({
						label: v.roleName, value: v.id
					})))
				} else {
					setRoleList([])
				}
			}
		}
		 

		setShowModal(true)
		let defaultFormData = {}
		Object.keys(rowInfo).forEach(k => {
			if (k === 'houseAreaAvailable') {
				defaultFormData.houseAreaList = Object.keys(rowInfo.houseAreaAvailable).map(v => ({houseArea: v, value: rowInfo.houseAreaAvailable[v]}))
			} else if (dateColumn.includes(k) && rowInfo[k]) {
				defaultFormData[k] = dayjs(rowInfo[k])
			} else if (['endDate', 'fwcExpiredDate', 'mastExpiredDate'].includes(k) && rowInfo[k]) {
				defaultFormData[k] = dayjs(rowInfo[k])
			} else if (k === 'workHistories') {
				// workHistories Special Handle
				defaultFormData[k] = rowInfo[k].map(v => ({
					...v,
					workStartedAt: dayjs(v.workStartedAt),
					workEndedAt: dayjs(v.workEndedAt),
				}))
				defaultFormData.FOH_workhistory = defaultFormData[k].filter(v => v.houseArea === 'FOH')
				defaultFormData.BOH_workhistory = defaultFormData[k].filter(v => v.houseArea === 'BOH')
			} else if (k === 'comment') {
				defaultFormData.employeeNote = rowInfo[k]?.employeeNote
				defaultFormData.complaints = rowInfo[k]?.complaints
				defaultFormData.others = rowInfo[k]?.others
			} else {
				defaultFormData[k] = rowInfo[k]
			}
		})
		setFormData(defaultFormData)
	}

	const batchEditForm = () => {
		if (selectedRows.length === 0) {
			message.warning('Please Select One Record')
			return
		}
		setShowBatchModal(true)
	}

	const calclateTips = async (record) => {
		try {
			const response = await postCalculateTips(companyId, record.id)
			message.success('Calculate Tips successfully')
		} catch (err) {
			message.error('Failed to Calculate Tips.')
		}
	}

	const handleDelete = async deletingItem => {
		try {
			const response = await service.delete({url: endpoint + '/' + deletingItem.id})
			message.success('Record created successfully')
			refreshList()
		} catch (err) {
			message.error('Failed to delete record.')
		}
	}

	const refreshList = async () => {
		if (formType === "PropertyInfo") {
			// refresh property list cache
			fetchProperties(companyId)
		}
		const params = {
			...searchParams,
			page: page - 1,
			pageSize,
		}
		setSearchParams(params)
		await fetchData(params)
	}

	const onDownload = () => {
		downloadActions && downloadActions(data, tableConfig)
	}

	// ***********************************************************

	// const handleDownload = () => {
	// 	const headers = Object.keys(data[0] || {}).map(key => ({label: key, key}))
	// 	const csvData = data.map(item => {
	// 		return Object.keys(item).reduce((acc, key) => ({...acc, [key]: item[key]}), {})
	// 	})
	// 	return {headers, data: csvData}
	// }

	if (loading) return <p>Loading...</p>
	// if (error || fetchError) return <p>Error: {error || fetchError}</p>
	return (
		<div className="generic-table-container">
			<h2>{tableName || 'Table'}</h2>
			{extendOptions.showTopAction ? (
				<div className="table-controls">
					{extendOptions.topAddBtn ? (
						formType === 'Shift' ? (
							<Button className="create-button" type="primary" onClick={() => createOrEditForm({propertyId})} icon={<PlusOutlined />}>
								Create Today's Report
							</Button>
						) : formType === 'EmployeeInfo' ? (
							<Button className="create-button" type="primary" onClick={() => createOrEditForm({propertyId: propertyId?Number(propertyId):undefined})} icon={<PlusOutlined />}>
								Create New Record
							</Button>
						) : (
							<Button className="create-button" type="primary" onClick={() => createOrEditForm({})} icon={<PlusOutlined />}>
								Create New Record
							</Button>
						)
					) : null}
					{
						extendOptions.topBatchEdit ? 
						<Button className="create-button" type="primary" onClick={() => batchEditForm()} icon={<EditOutlined />}>
							Batch Edit Record
						</Button>
						: null
					}
					{extendOptions.topDownloadBtn ? (
						<Button className="download-button" type="primary" icon={<DownloadOutlined />} onClick={onDownload}>
							Download CSV
						</Button>
					) : null}
					{extendOptions.topHideColumnBtn ? (
						<HideColumnModal 
							columns={tableConfig} 
							defaultCheckedList={checkedList}
							onChange={(val) => {setCheckedList(val)}}
						/>
					) : null}
				</div>
			) : null}
			<div className="filters-container">{filtersConfig && <CustomForm onFinish={onSearch} {...formConfig} />}</div>
			<ConfigProvider>
				<Table
					bordered
					rowKey={'id'}
					size="small"
					columns={tableConfig}
					dataSource={data}
					rowSelection={rowSelection}
					pagination={{
						showQuickJumper: true,
						showSizeChanger: true,
						current: page,
						pageSize: pageSize,
						total: total,
						pageSizeOptions: [10, 20, 50]
					}}
					locale={{
						emptyText: <Empty description={noDataMessage}></Empty>,
					}}
					rowClassName={rowClassName}
					summary={summaryFunc && summaryFunc(summary)}
					onChange={onTableChange}
				/>
			</ConfigProvider>
			{formType === 'Shift'
				? showModal && (
						<ShiftModal
							isModalOpen={showModal}
							setModalOpen={setShowModal}
							companyId={companyId}
							propertyId={propertyId}
							endpoint={endpoint}
							defaultFormData={formData}
							initShiftTypeList={shiftTypeList}
							initHouseAreaList={houseAreaList}
							refreshList={refreshList}
						></ShiftModal>
				  )
				: showModal && (
						<SubmitForm
							isModalOpen={showModal}
							setModalOpen={setShowModal}
							companyId={companyId}
							propertyId={propertyId}
							endpoint={endpoint}
							defaultFormData={formData}
							formType={formType}
							refreshList={refreshList}
							initHouseAreaList={houseAreaList}
							initPositionList={positionList}
							initRoleList={roleList}
						></SubmitForm>
				  )}
			{
				formType === 'EmployeeInfo' && showBatchModal ?
					<BatchEditEmployee
						isModalOpen={showBatchModal}
						setModalOpen={setShowBatchModal}
						checkedList={selectedRows}
						setCheckedList={setSelectedRows}
						companyId={companyId}
						refreshList={refreshList}
					></BatchEditEmployee>
				:null
			}
		</div>
	)
}

export default GenericTable
